var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"contact-container"},[_c('h1',{staticClass:"contact-header"},[_vm._v("Formulaire de Contact")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"action":_vm.action,"method":"post"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"column-firstname"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Prénom","error-messages":errors,"required":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)})],1),_c('div',{staticClass:"column-lastname"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nom","required":"","error-messages":errors},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1)]),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"contact-email",attrs:{"label":"Email","required":"","error-messages":errors},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Objet","items":_vm.subjectsList,"attach":"","required":"","error-messages":errors},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"input-7-1","label":"Votre message","placeholder":"Tapez ici votre message...","required":"","error-messages":errors,"counter":"2000"},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}})]}}],null,true)}),_c('v-checkbox',{attrs:{"label":"Je souhaite recevoir la newsletter de l'édition."},model:{value:(_vm.form.newsletter),callback:function ($$v) {_vm.$set(_vm.form, "newsletter", $$v)},expression:"form.newsletter"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" ENVOYER ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }