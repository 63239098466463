<template>
    <v-container class="contact-container">
        <h1 class="contact-header">Formulaire de Contact</h1>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form :action="action" method="post">
                <div class="row">
                    <div class="column-firstname">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <v-text-field
                                v-model="form.firstname"
                                label="Prénom"
                                :error-messages="errors"
                                required
                            />
                        </ValidationProvider>
                    </div>
                    <div class="column-lastname">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <v-text-field
                                v-model="form.lastname"
                                label="Nom"
                                required
                                :error-messages="errors"
                            />
                        </ValidationProvider>
                    </div>
                </div>
                <ValidationProvider v-slot="{ errors }" rules="required|email">
                    <v-text-field
                        v-model="form.email"
                        class="contact-email"
                        label="Email"
                        required
                        :error-messages="errors"
                    ></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-select
                        v-model="form.subject"
                        label="Objet"
                        :items="subjectsList"
                        attach
                        required
                        :error-messages="errors"
                    >
                    </v-select>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-textarea
                        v-model="form.text"
                        name="input-7-1"
                        label="Votre message"
                        placeholder="Tapez ici votre message..."
                        required
                        :error-messages="errors"
                        counter="2000"
                    ></v-textarea>
                </ValidationProvider>
                <v-checkbox
                    v-model="form.newsletter"
                    label="Je souhaite recevoir la newsletter de l'édition."
                />
                <v-btn class="mr-4" :disabled="invalid" @click="submit">
                    ENVOYER
                </v-btn>
            </form>
        </ValidationObserver>
    </v-container>
</template>

<script>
import { email, required } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import axios from 'axios';

extend('email', {
    ...email,
    message: "L'adresse email doit être valide.",
});

extend('required', {
    ...required,
    message: 'Ce champ est obligatoire.',
});
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            form: {
                email: '',
                text: '',
                firstname: '',
                lastname: '',
                subject: '',
                newsletter: false,
            },
            action: process.env.VUE_APP_API_URL + '/contact',
            subjectsList: [
                'Commande',
                "Soumission d'un manuscript",
                'Fanart',
                'Autre',
            ],
        };
    },
    methods: {
        async submit() {
            this.$refs.observer.validate();
            const res = await axios.post(this.action, this.form);
            if (res.status === 200) {
                this.$toasted.show('Mail envoyé !', { type: 'info' });
                this.clear();
            } else {
                this.$toasted.show(`Erreur : ${res.error}`, { type: 'error' });
                this.$toasted.show(
                    "Le mail n'a pu être envoyé... Je vous invite à contacter : contact@editions-de-lembuscade.com pour ce problème",
                    { type: 'error', duration: 5000 }
                );
            }
        },
        clear() {
            this.$refs.observer.reset();
            Object.keys(this.form).forEach((field) => {
                this.form[field] = '';
            });
        },
    },
};
</script>
<style>
.contact-header {
    text-align: left;
    margin-top: 1rem;
}

.contact-container {
    margin: auto;
    margin-top: 3em;
    width: 50em;
    border: 1px solid black;
    border-radius: 30px;
}

.contact-email {
    width: 30em;
}

.row {
    padding: 1rem;
}

.column-firstname {
    margin-right: 2rem;
}

.column-lastname {
    margin-left: 2rem;
}
</style>
